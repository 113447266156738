<template>
    <div class="memod-footer">
        <span>&copy; {{ currentYear }} Memo'd Technologies, LLC. Contact us at <a href="mailto:beta@memod.com">beta@memod.com</a></span>
    </div>
</template>

<script>
export default {
    name: "MemodFooter",
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>

<style lang="scss" scoped>
.memod-footer {
    border-top: 1px solid rgba(112, 112, 112, .55);
    padding: 50px 0;
    font-size: 16px;
    color: #6E6E6E;
    text-align: center;

    a {
        color: #6E6E6E;
    }
}
</style>
